// frontend/src/components/AlbumPage.jsx

import React, { useEffect, useState} from 'react';
import { useParams } from 'react-router-dom';
import { Row, Col, Image, Spin, Alert, Upload, Button, message, Tooltip } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import axios from 'axios';
import './AlbumPage.css'; // Importér CSS for styling
import { DownloadOutlined } from '@ant-design/icons';

const AlbumPage = () => {
  const { albumName } = useParams();
  const [mediaFiles, setMediaFiles] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [uploading, setUploading] = useState(false);

  useEffect(() => {
    fetchMediaFiles();
  }, [albumName]);

  const fetchMediaFiles = () => {
    setLoading(true);
    axios.get(`/api/albums/${encodeURIComponent(albumName)}`)
      .then(response => {
        if (Array.isArray(response.data)) {
          setMediaFiles(response.data);
          console.log('Medier hentet:', response.data);
        } else {
          console.error('Forventede en array, men modtog:', response.data);
          setError('Uventet svar format fra serveren.');
        }
        setLoading(false);
      })
      .catch(err => {
        console.error('Fejl ved hentning af medier:', err);
        setError('Kunne ikke hente medier');
        setLoading(false);
      });
  };

  const customRequest = async (options) => {
    const { onSuccess, onError, file, onProgress } = options;

    const formData = new FormData();
    formData.append('images', file);

    try {
      setUploading(true);
      await axios.post(`/api/albums/${encodeURIComponent(albumName)}/upload`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        onUploadProgress: (event) => {
          const percent = Math.round((event.loaded / event.total) * 100);
          onProgress({ percent });
        },
      });
      onSuccess("OK");
      message.success(`${file.name} uploadet succesfuldt`);
      fetchMediaFiles(); // Opdater medierne efter upload
    } catch (error) {
      console.error(error);
      onError(error);
      message.error(`${file.name} upload fejlede`);
    } finally {
      setUploading(false);
    }
  };

  if (loading) return <Spin tip="Indlæser medier..." />;
  if (error) return <Alert message="Fejl" description={error} type="error" showIcon />;

  return (
    <div className="album-page-container">
      <h2>{albumName}</h2>

      {albumName === 'Jeresbilleder' && (
        <Upload
          multiple
          customRequest={customRequest}
          showUploadList={false}
          accept="image/*, video/mp4" // Tillad både billeder og MP4 videoer
        >
          <Button type="primary" icon={<UploadOutlined />} loading={uploading}>
            Upload Medier
          </Button>
        </Upload>
      )}

      <Row gutter={[16, 16]} style={{ marginTop: '20px' }}>
        {mediaFiles.map((file, index) => {
          const { name, downloadUrl, publicUrl } = file;

          // Bestem filtypen ved at splitte filnavnet
          const ext = name.split('.').pop().toLowerCase();

          if (['jpeg', 'jpg', 'png', 'gif', 'webp'].includes(ext)) {
            // Render billede
            return (
              <Col xs={24} sm={12} md={8} lg={6} key={index}>
                <div className="media-container">
                  <Image
                    src={downloadUrl}
                    alt={name}
                    style={{ width: '100%' }}
                    placeholder={<Spin />}
                    fallback="/path/to/fallback-image.png" // Optional: fallback image
                  />
                  <Tooltip title="gem på din pc">
                    <a href={publicUrl} download className="download-button">
                    <DownloadOutlined />
                    </a>
                  </Tooltip>
                </div>
              </Col>
            );
          } else if (ext === 'mp4') {
            // Render video
            return (
              <Col xs={24} sm={12} md={8} lg={6} key={index}>
                <div className="media-container">
                  <video 
                    controls 
                    style={{ width: '100%', borderRadius: '8px' }}
                  >
                    <source src={downloadUrl} type="video/mp4" />
                    Din browser understøtter ikke video-tag.
                  </video>
                  <Tooltip title="gem på din pc">
                    <a href={downloadUrl} download className="download-button">
                    <DownloadOutlined />
                    </a>
                  </Tooltip>
                </div>
              </Col>
            );
          } else {
            // Hvis filtypen ikke er genkendt, kan du returnere null eller en placeholder
            return null;
          }
        })}
      </Row>
    </div>
  );
};

export default AlbumPage;
