// frontend/src/components/TopMenu.jsx

import React, { useEffect, useState } from 'react';
import { Menu, Spin, Alert } from 'antd';
import { Link, useLocation } from 'react-router-dom';
import axios from 'axios';
import './TopMenu.css'; // Importér den opdaterede CSS

const TopMenu = () => {
  const [albums, setAlbums] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const location = useLocation();

  useEffect(() => {
    axios.get('/api/albums') // Brug relative sti til API
      .then(response => {
        console.log('Albums hentet:', response.data); // Log albums for fejlfinding
        if (Array.isArray(response.data)) {
          // Filtrer "Forside" ud af albumslisten, hvis nødvendigt
          const filteredAlbums = response.data.filter(album => album.toLowerCase() !== 'forside');
          setAlbums(filteredAlbums);
        } else {
          console.error('Forventede en array, men modtog:', response.data);
          setError('Uventet svar format fra serveren.');
        }
        setLoading(false);
      })
      .catch(err => {
        console.error('Fejl ved hentning af albums:', err); // Log fejl for fejlfinding
        setError('Kunne ikke hente albums');
        setLoading(false);
      });
  }, []);
  
  const getDisplayName = (album) => {
    const renameMap = {
      'Jeresbilleder': 'Jeres billeder',
      'FyrværkeriogBrudevals': 'Fyrværkeri og brudevals',
      'Myselfie': 'My selfie'
      // Tilføj flere her, hvis nødvendigt
    };
    return renameMap[album] || album; // Brug omdøbt navn hvis det findes, ellers brug det originale navn
  };

  if (loading) return <Spin tip="Indlæser menu..." />;
  if (error) return <Alert message="Fejl" description={error} type="error" showIcon />;

  // Bestem hvilken menu-item der er valgt baseret på URL
  const pathParts = location.pathname.split('/');
  const selectedKey = pathParts[1] === 'album' ? decodeURIComponent(pathParts[2]) : 'home';

  return (
    <Menu
      mode="horizontal"
      selectedKeys={[selectedKey]}
      className="custom-menu" /* Sørg for, at ingen inline-stile påvirker menu */
      style={{ background: 'transparent', zIndex: 3, position: 'absolute', top: 0, left: 0, width: '100%' }} /* Anvend inline-stil for at overstyre baggrund */
    >
        <Menu.Item key="home">
        <Link to="/">Forside</Link>
      </Menu.Item>
      {albums.map(album => (

        <Menu.Item key={album}>
          <Link to={`/album/${encodeURIComponent(album)}`}>{getDisplayName(album)}</Link>
        </Menu.Item>
      ))}
    </Menu>
  );
};

export default TopMenu;
