// frontend/src/App.js

import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import AlbumList from './components/AlbumList';
import AlbumPage from './components/AlbumPage';
import TopMenu from './components/TopMenu';
import { Layout } from 'antd';
// frontend/src/index.js eller frontend/src/App.js

//import "slick-carousel/slick/slick.css"; 
//import "slick-carousel/slick/slick-theme.css";


const { Header, Content } = Layout;

const App = () => {
  return (
    <Router>
      <Layout>
        <Header style={{ padding: 0 }}>
          <TopMenu />
        </Header>
        <Content style={{ marginTop: 64, padding: '20px' }}> {/* MarginTop for at undgå overlap */}
          <Routes>
            <Route path="/" element={<AlbumList />} />
            <Route path="/album/:albumName" element={<AlbumPage />} />
          </Routes>
        </Content>
      </Layout>
    </Router>
  );
};

export default App;
